<template>
<div v-if="items.length" class="filters__group">
    <p class="filters__group-name" @click="toggleGroup()">
        {{ title }}
        <span class="filters__chevron" :class="{ 'active': isActiveChevronName, 'collapse': localCollapse }">
            <img v-if="!isActiveChevronName" src="@/assets/images/svg/chevron-down.svg" alt="">
            <img v-else src="@/assets/images/svg/chevron-down_white.svg" alt="">
        </span>
    </p>
    <p v-if="isActiveChevronName && !localCollapse" class="filters__btn" @click="clear()">Очистить</p>
    <SidebarFilterGroupCheckboxList v-if="type === 'checkbox'" :items="items" :action="action" :expand="expand" :collapse="localCollapse" :selectedItems="selectedItems" />
</div>
</template>

<script lang="js">
import SidebarFilterGroupCheckboxList from '@/components/sidebar/SidebarFilterGroupCheckboxList.vue';

export default {
    name: 'SidebarFilterGroup',
    components: {
        SidebarFilterGroupCheckboxList
    },
    props: {
        title: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        selectedItems: {
            type: Array,
            required: true
        },
        action: {
            type: Function,
            required: true
        },
        expand: {
            type: Boolean,
            default: false
        },
        collapse: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localCollapse: this.collapse
        };
    },
    computed: {
        isActiveChevronName() {
            return this.selectedItems.filter(item => this.items.some(obj => obj.id === item)).length > 0;
        }
    },
    methods: {
        toggleGroup() {
            this.localCollapse = !this.localCollapse;
        },
        clear() {
            const filteredItems = this.selectedItems.filter(item => !this.items.some(obj => obj.id === item));
            this.action(filteredItems);
        }
    }
}
</script>

<style lang="scss" scoped>
.filters {
    &__group {
        padding: 0 10px;
        margin-bottom: 20px;

        &-name {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            cursor: pointer;
        }
    }

    &__chevron {
        display: inline-flex;
        width: 24px;
        height: 24px;
        margin-left: 5px;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        transition: background-color .5s ease;

        img {
            width: 12px;
            height: 8px;
            transform: rotate(0deg);
            transition: transform .3s ease;
        }
    }

    &__chevron.active {
        background-color: #58BCAF;
    }

    &__chevron.collapse {
        img {
            transform: rotate(-90deg);
        }
    }

    &__btn {
        font-size: 18px;
        line-height: 24px;
        color: #DC143C;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 20px;
        }

        &--apply {
            color: #fff;
        }

        img {
            width: 8px;
            height: 12px;
            margin-left: 8px;
        }
    }
}
</style>
