<template>
    <div>
        <ul class="filters__options" :class="{ 'expand': localExpand, 'collapse': collapse }">
            <li v-for="(item) in items" :key="item.id">
                <input class="input-hidden" :id="item.id" type="checkbox" :checked="selectedItems.includes(item.id)" @click="checkboxHandler(item.id)">
                <label class="label-checkbox" :for="item.id">
                    <span v-if="item.image" class="type-icon" :style="`color: ${getColorIcon(item.code)}`">
                        <svg :data-src="item.image ? item.image.thumbnail_link : item.link" fill="black"></svg>
                    </span>
                    {{ item.name }}
                </label>
            </li>
        </ul>
        <p v-if="!localExpand && !collapse" class="filters__btn" @click="toggleExpand()">
            Показать все
            <img src="@/assets/images/svg/chevron-right.svg" alt="">
        </p>
    </div>
</template>

<script lang="js">
import DirectionColors from '@/helpers/DirectionColors';

export default {
    name: 'SidebarFilterGroupCheckboxList',
    data() {
        return {
            localExpand: this.expand
        };
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        selectedItems: {
            type: Array,
            required: true
        },
        action: {
            type: Function,
            required: true
        },
        expand: {
            type: Boolean,
            default: false
        },
        collapse: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        checkboxHandler(id) {
            const index = this.selectedItems.indexOf(id);

            if (index === -1) {
                this.selectedItems.push(id);
            } else {
                this.selectedItems.splice(index, 1);
            }

            this.action(this.selectedItems);
        },
        getColorIcon(code) {
            if (code) {
                return DirectionColors[code.toLowerCase()];
            }
            return '';
        },
        toggleExpand() {
            this.localExpand = !this.localExpand;
        }
    }
}
</script>

<style lang="scss" scoped>
.filters {
    &__options {
        margin-top: 10px;

        li:nth-child(n+5) {
            display: none;
        }
    }

    &__options.expand {
        li:nth-child(n+5) {
            display: block;
        }
    }

    &__options.collapse {
        display: none;
    }

    &__btn {
        font-size: 18px;
        line-height: 24px;
        color: #DC143C;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 20px;
        }

        &--apply {
            color: #fff;
        }

        img {
            width: 8px;
            height: 12px;
            margin-left: 8px;
        }
    }
}

.input {
    &-hidden {
        position: fixed;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
    }
}

input:checked+.label-checkbox::before {
    background-color: #000;
}

input:checked+.label-checkbox::after {
    opacity: 1;
}

.label-checkbox {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 5px 0 5px 29px;
    font-size: 18px;
    line-height: 20px;
    background-color: #fff;
    transition: background-color .3s ease;
    cursor: pointer;

    &:hover {
        background-color: #E5E5E5;
    }

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 19px;
        height: 19px;
        border: 1px solid #000000;
        background-color: #fff;
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 4px;
        width: 12px;
        height: 12px;
        background: url("../../assets/images/svg/checked.svg") no-repeat;
        opacity: 0;
        z-index: 2;
    }
}

.type-icon {
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 39px;
    height: 39px;
    margin-right: 10px;
    border: 4px solid currentColor;
    border-radius: 50%;
    background-color: #fff;

    svg {
        width: 20px;
        height: 20px;
        fill: currentColor;
    }
}
</style>
